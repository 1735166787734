<template>
    <div class="pt-4 mb-36">
      <hr class="my-2 h-px w-11/12 mx-auto">
      <PeopleEntry v-for="contact in people" :key="contact.id" :Name="contact.name" :Email="contact.email" :Phone="contact.phone"/>
    </div>
  
</template>
  <script>
  import axios from 'axios';
  import PeopleEntry from "./PeopleEntry.vue";
  export default {
    data() {
      return{
        destinationName: '',
        destinationPhone: '',
        destinationEmail: '',
        people: [],
        user: this.$auth0.user
      }
    },
    name: "PeopleListing",
    components: {
      PeopleEntry,
    },
    methods: {
      loadContacts(){
        var userData = this.$auth0.user;
        console.log(userData.rawValue);
        axios.get('https://api.inhearten.com/api/emails/contacts', {
        })
        .then((response) => {
        this.people = response.data
        })
      },
      saveContact(){
        let formData = new FormData();
  
        formData.append('name', this.destinationName)
        formData.append('email', this.destinationEmail)
        formData.append('phone', this.destinationPhone)
  
        axios.post( 'https://api.inhearten.com/api/emails/addcontact',{
        },
        formData,
        {
  
        }
        ).then(function(){
          console.log('success');
          this.loadContacts();
        })
      
      }
    },
    created() {
      this.$auth0.checkSession()
    
    if (this.$auth0.isAuthenticated.value == false){
      console.log("it is not!")
      this.$auth0.loginWithPopup();      
    }
      this.loadContacts()
      }
  
    }
  
  </script>
  